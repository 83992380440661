<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<div class="ticketing">
			<div v-html="list.bodyContent">
				{{list.bodyContent}}
			</div>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
	import { selectContentListById } from '@/api/index.js'
	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 头部导航
	import navigation from '@/components/navigation.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'TICKETING',
				// 头部导航图片
				headImg:'/index/ticketing.png',
				// 导航值
				navigationvalue:false,
				list:{}
			}
		},
		components:
		{
			headLogo,
			navigation,
			bottomNavigation,
			meauDialog
		},
		created()
		{
			this.getList()
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			getList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_TICKETING'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.$router.push(
						{
							path:'/error',
							item:2,
						})
					}
					else
					{
						console.log(response.data.data)
						this.list=response.data.data[0];
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ticketing{
		margin: 0 auto;
		display: inline-block;
		text-align: justify;
		text-justify: distribute-all-lines;
	}
	::v-deep img{
		max-width:100%;
	}
</style>